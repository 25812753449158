const SELECTORS = {
    nav: 'nav',
    menu: 'menu',
    navLink: '.nav__link',
    toggleBtn: 'menu-toggle',
    toggleContact: 'contact-toggle',
    animationCircle: 'animation-circle'
}

const CLASSES = {
    menuListVisible: 'nav__list--visible',
    navVisible     : 'isVisible',
    menuOpen       : 'menu-open',

    menuContent: 'menu-content--',
}

export default class Navigation {
    constructor () {
        this.nav = RM.G.id(SELECTORS.nav)
        if (this.nav) {
            this.init()
        }
    }

    init () {
        this.isMenuOpen      = false
        this.menu            = RM.G.id(SELECTORS.menu)
        this.toggleBtn       = RM.G.id(SELECTORS.toggleBtn)
        this.toggleContact   = RM.G.id(SELECTORS.toggleContact)
        this.navLinks        = Array.from( RM.Select.el(SELECTORS.navLink, this.menu) )
        this.animationCircle = RM.G.id(SELECTORS.animationCircle)

        RM.L(this.toggleBtn, 'a', 'click', () => this.toggleMenu('menu'))
        RM.L(this.toggleContact, 'a', 'click', () => this.toggleMenu('contact'))
        // this.toggleBtn.addEventListener('click', () => this.toggleMenu())
        RM.L(this.nav, 'a', 'keydown', e => this.handleTabPress(e))
        // this.nav.addEventListener('keydown', e => this.handleTabPress(e))

        this.ro = new RM.RO({
          cb: () => this.setCircleSize(),
          throttleDelay: 200
        })
        this.ro.on()
        // window.addEventListener(
        //     'resize',
        //     debounce(() => this.setCircleSize(), 200)
        // )

        this.setCircleSize()
    }

    toggleMenu (type) {
        if ( this.isMenuOpen ) { // => close
            const delay = new RM.Delay(_ => {
                Util.removeClass(App.el.body, CLASSES.menuContent + 'menu')
                Util.removeClass(App.el.body, CLASSES.menuContent + 'contact')
            }, variables.transition_slow)
            delay.run()
        } else { // => open
            Util.removeClass(App.el.body, CLASSES.menuContent + 'menu')
            Util.removeClass(App.el.body, CLASSES.menuContent + 'contact')
            Util.addClass(App.el.body, CLASSES.menuContent + type)
        }

        this.isMenuOpen = !this.isMenuOpen

        this.toggleBtn.setAttribute('aria-expanded', String(this.isMenuOpen))
        Util.toggleClass(App.el.body, CLASSES.menuOpen, this.isMenuOpen)

        const delay = new RM.Delay(_ => {
            Util.toggleClass(this.nav, CLASSES.navVisible, this.isMenuOpen)
        }, 50)
        delay.run()
    }

    setCircleSize () {
        const screen = Util.getWindowDimensions()
        const diameter = Math.sqrt(screen.height ** 2 + screen.width ** 2)

        this.animationCircle.style.width  = `${diameter * 2}px`
        this.animationCircle.style.height = `${diameter * 2}px`
        this.animationCircle.style.top    = `${-diameter}px`
        this.animationCircle.style.left   = `${-diameter}px`
    }

    handleTabPress (e) {
        if (!this.isMenuOpen || e.ctrlKey || e.metaKey || e.altKey) {
            return
        }

        const lastLink = this.navLinks[this.navLinks.length - 1]
        switch (e.keyCode) {
            case 27: // ESC
                this.toggleMenu()
                this.toggleBtn.focus()
                break

            case 9: // TAB
                if (e.shiftKey) {
                    if (document.activeElement === this.toggleBtn) {
                        lastLink.focus()
                        e.preventDefault()
                    }
                } else if (document.activeElement === lastLink) {
                    this.toggleBtn.focus()
                    e.preventDefault()
                }
                break

            default:
                break
        }
    }
}
