/*

──────────────────────────────────────────
──────────────────────────────────────────
LERP
──────────────────────────────────────────
──────────────────────────────────────────

RM.Lerp(start, end, multiplier)

*/

RM.Lerp = function (s, e, m) {
  return s * (1 - m) + e * m
}