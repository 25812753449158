/*

──────────────────────────────────────────
──────────────────────────────────────────
INDEX
──────────────────────────────────────────
──────────────────────────────────────────

const elementLiIndex    = RM.Index.list(liElement)
const elementClassIndex = RM.Index.class(elementWithClass, className)

*/

RM.Index = {
    i: function (n, el) {
        var elL = el.length
        for (var i = 0; i < elL; i++) {
            if (n === el[i]) {
                return i
            }
        }
        return -1
    },

    list: function (n) {
        var el = n.parentNode.children
        return this.i(n, el)
    },

    class: function (n, cN) {
        var el = RM.G.class(cN)
        return this.i(n, el)
    }
}