/*

──────────────────────────────────────────
──────────────────────────────────────────
DOM
──────────────────────────────────────────
──────────────────────────────────────────

const body = RM.Dom.body

*/

RM.Dom = {
    html: document.documentElement,
    body: document.body
}