/*

──────────────────────────────────────────
──────────────────────────────────────────
HAS
──────────────────────────────────────────
──────────────────────────────────────────

RM.Has(object, 'property')

*/

RM.Has = function (obj, p) {
    return obj ? hasOwnProperty.call(obj, p) : false
}